html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */

/**
 * Remove the margin in all browsers.
 */

body {
    margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */

main {
    display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
    background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
    font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
    font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
    border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
    /* 1 */
    overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
    /* 1 */
    text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'] {
    -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
    vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
    overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type='checkbox'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
    display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
    display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
    display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
    display: none;
}

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

button {
    background-color: transparent;
    background-image: none;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
    margin: 0;
    padding: 0;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/**
 * Tailwind custom reset styles
 */

/**
 * 1. Use the user's configured `sans` font-family (with Tailwind's default
 *    sans-serif font stack as a fallback) as a sane default.
 * 2. Use Tailwind's default "normal" line-height so the user isn't forced
 *    to override it to ensure consistency even when using the default theme.
 */

html {
    font-family: 'ProstoOne', 'Helvetica', 'Arial', sans-serif; /* 1 */
    line-height: 1.5; /* 2 */
}

/**
 * 1. Prevent padding and border from affecting element width.
 *
 *    We used to set this in the html element and inherit from
 *    the parent element for everything else. This caused issues
 *    in shadow-dom-enhanced elements like <details> where the content
 *    is wrapped by a div with box-sizing set to `content-box`.
 *
 *    https://github.com/mozdevs/cssremedy/issues/4
 *
 *
 * 2. Allow adding a border to an element by just adding a border-width.
 *
 *    By default, the way the browser specifies that an element should have no
 *    border is by setting it's border-style to `none` in the user-agent
 *    stylesheet.
 *
 *    In order to easily add borders to elements by just setting the `border-width`
 *    property, we change the default border-style for all elements to `solid`, and
 *    use border-width to hide them instead. This way our `border` utilities only
 *    need to set the `border-width` property instead of the entire `border`
 *    shorthand, making our border utilities much more straightforward to compose.
 *
 *    https://github.com/tailwindcss/tailwindcss/pull/116
 */

*,
::before,
::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: #e2e8f0; /* 2 */
}

/*
 * Ensure horizontal rules are visible by default
 */

hr {
    border-top-width: 1px;
}

/**
 * Undo the `border-style: none` reset that Normalize applies to images so that
 * our `border-{width}` utilities have the expected effect.
 *
 * The Normalize reset is unnecessary for us since we default the border-width
 * to 0 on all elements.
 *
 * https://github.com/tailwindcss/tailwindcss/issues/362
 */

img {
    border-style: solid;
}

textarea {
    resize: vertical;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #a0aec0;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #a0aec0;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    color: #a0aec0;
}

input::placeholder,
textarea::placeholder {
    color: #a0aec0;
}

button,
[role='button'] {
    cursor: pointer;
}

table {
    border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

a {
    color: inherit;
    text-decoration: inherit;
}

/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

/**
 * Use the configured 'mono' font family for elements that
 * are expected to be rendered with a monospace font, falling
 * back to the system monospace stack if there is no configured
 * 'mono' font family.
 */

pre,
code,
kbd,
samp {
    font-family: 'Courier New', monospace;
}

/**
 * Make replaced elements `display: block` by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with `svg` added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their instrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
    max-width: 100%;
    height: auto;
}

.container {
    width: 100%;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

@media not print {
    .form-checkbox::-ms-check {
        border-width: 1px;
        color: transparent;
        background: inherit;
        border-color: inherit;
        border-radius: inherit;
    }
}

.form-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1em;
    width: 1em;
    color: #222;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    border-radius: 0.25rem;
}

.form-checkbox:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(224, 67, 50, 0.5);
    border-color: #e04332;
}

::-moz-selection {
    background: #90cdf4;
}
::selection {
    background: #90cdf4;
}

.bg-transparent {
    background-color: transparent;
}

.bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
}

.bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
}

.bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
}

.bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
}

.bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff0f0;
    background-color: rgba(255, 240, 240, var(--bg-opacity));
}

.bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
}

.bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
}

.bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
}

.bg-red-600 {
    --bg-opacity: 1;
    background-color: #e04332;
    background-color: rgba(224, 67, 50, var(--bg-opacity));
}

.bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
}

.bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
}

.bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
}

.bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
}

.bg-green-100 {
    --bg-opacity: 1;
    background-color: #effbf3;
    background-color: rgba(239, 251, 243, var(--bg-opacity));
}

.bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
}

.bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
}

.bg-green-600 {
    --bg-opacity: 1;
    background-color: #4caf50;
    background-color: rgba(76, 175, 80, var(--bg-opacity));
}

.bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
}

.bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
}

.bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
}

.bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
}

.bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
}

.bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
}

.bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
}

.bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
}

.bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
}

.bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
}

.bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
}

.active\:bg-gray-100:active {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.active\:bg-gray-700:active {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.active\:bg-blue-600:active {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
}

.active\:bg-indigo-600:active {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
}

.bg-center {
    background-position: center;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-cover {
    background-size: cover;
}

.bg-full {
    background-size: 100%;
}

.border-collapse {
    border-collapse: collapse;
}

.border-transparent {
    border-color: transparent;
}

.border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
}

.border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
}

.border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
}

.border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
}

.border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
}

.border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
}

.border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
}

.border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
}

.border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
}

.border-red-600 {
    --border-opacity: 1;
    border-color: #e04332;
    border-color: rgba(224, 67, 50, var(--border-opacity));
}

.border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
}

.border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
}

.border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
}

.rounded {
    border-radius: 0.25rem;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.unrounded {
    border-radius: 0 !important;
}

.rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.rounded-t-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.border-solid {
    border-style: solid;
}

.border-none {
    border-style: none;
}

.border-0 {
    border-width: 0;
}

.border-2 {
    border-width: 2px;
}

.border {
    border-width: 1px;
}

.border-t-0 {
    border-top-width: 0;
}

.border-r-0 {
    border-right-width: 0;
}

.border-l-0 {
    border-left-width: 0;
}

.border-t {
    border-top-width: 1px;
}

.border-b {
    border-bottom-width: 1px;
}

.cursor-pointer {
    cursor: pointer;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.table {
    display: table;
}

.hidden {
    display: none;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-center {
    align-items: center;
}

.items-stretch {
    align-items: stretch;
}

.content-center {
    align-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-auto {
    flex: 1 1 auto;
}

.flex-initial {
    flex: 0 1 auto;
}

.flex-grow {
    flex-grow: 1;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.h-0 {
    height: 0;
}

.h-2 {
    height: 0.5rem;
}

.h-5 {
    height: 1.25rem;
}

.h-8 {
    height: 2rem;
}

.h-10 {
    height: 2.5rem;
}

.h-12 {
    height: 3rem;
}

.h-16 {
    height: 4rem;
}

.h-20 {
    height: 5rem;
}

.h-auto {
    height: auto;
}

.h-full {
    height: 100%;
}

.h-screen {
    height: 100vh;
}

.h-95-px {
    height: 95px;
}

.h-70-px {
    height: 70px;
}

.h-350-px {
    height: 350px;
}

.h-500-px {
    height: 500px;
}

.h-600-px {
    height: 600px;
}

.text-55 {
    font-size: 55rem;
}

.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: 0.875rem;
}

.text-base {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.125rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-3xl {
    font-size: 1.875rem;
}

.text-4xl {
    font-size: 2.25rem;
}

.text-5xl {
    font-size: 3rem;
}

.leading-none {
    line-height: 1;
}

.leading-snug {
    line-height: 1.375;
}

.leading-normal {
    line-height: 1.5;
}

.leading-relaxed {
    line-height: 1.625;
}

.list-none {
    list-style-type: none;
}

.m-2 {
    margin: 0.5rem;
}

.m-3 {
    margin: 0.75rem;
}

.m-4 {
    margin: 1rem;
}

.-m-16 {
    margin: -4rem;
}

.-m-24 {
    margin: -6rem;
}

.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mr-3 {
    margin-right: 0.75rem;
}

.mb-3 {
    margin-bottom: 0.75rem;
}

.ml-3 {
    margin-left: 0.75rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mr-4 {
    margin-right: 1rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.ml-4 {
    margin-left: 1rem;
}

.mt-5 {
    margin-top: 1.25rem;
}

.mr-5 {
    margin-right: 1.25rem;
}

.mb-5 {
    margin-bottom: 1.25rem;
}

.ml-5 {
    margin-left: 1.25rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.mr-6 {
    margin-right: 1.5rem;
}

.mb-6 {
    margin-bottom: 1.5rem;
}

.ml-6 {
    margin-left: 1.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.ml-8 {
    margin-left: 2rem;
}

.mt-10 {
    margin-top: 2.5rem;
}

.mb-10 {
    margin-bottom: 2.5rem;
}

.mt-12 {
    margin-top: 3rem;
}

.mb-12 {
    margin-bottom: 3rem;
}

.mt-16 {
    margin-top: 4rem;
}

.mt-20 {
    margin-top: 5rem;
}

.mt-24 {
    margin-top: 6rem;
}

.mb-24 {
    margin-bottom: 6rem;
}

.mt-32 {
    margin-top: 8rem;
}

.mt-48 {
    margin-top: 12rem;
}

.mr-auto {
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
}

.-ml-4 {
    margin-left: -1rem;
}

.-mt-4 {
    margin-top: -1rem;
}

.-mb-4 {
    margin-bottom: -1rem;
}

.-mt-5 {
    margin-top: -1.25rem;
}

.-mt-6 {
    margin-top: -1.5rem;
}

.-mt-8 {
    margin-top: -2rem;
}

.-mt-10 {
    margin-top: -2.5rem;
}

.-mt-12 {
    margin-top: -3rem;
}

.-mt-14 {
    margin-top: -3.5rem;
}

.-mt-16 {
    margin-top: -4rem;
}

.-mt-20 {
    margin-top: -5rem;
}

.-ml-20 {
    margin-left: -5rem;
}

.-mt-24 {
    margin-top: -6rem;
}

.-mt-32 {
    margin-top: -8rem;
}

.-mt-48 {
    margin-top: -12rem;
}

.-mt-64 {
    margin-top: -16rem;
}

.last\:mt-0:last-child {
    margin-top: 0;
}

.last\:mb-0:last-child {
    margin-bottom: 0;
}

.last\:mr-0:last-child {
    margin-right: 0;
}

.last\:ml-0:last-child {
    margin-left: 0;
}

.hover\:-mt-4:hover {
    margin-top: 0;
}

.max-h-860-px {
    max-height: 860px;
}

.max-w-full {
    max-width: 100%;
}

.max-w-100-px {
    max-width: 100px;
}

.max-w-120-px {
    max-width: 120px;
}

.max-w-150-px {
    max-width: 150px;
}

.max-w-180-px {
    max-width: 180px;
}

.max-w-200-px {
    max-width: 200px;
}

.max-w-210-px {
    max-width: 210px;
}

.max-w-580-px {
    max-width: 580px;
}

.min-h-screen {
    min-height: 100vh;
}

.min-h-screen-75 {
    min-height: 75vh;
}

.min-w-0 {
    min-width: 0;
}

.min-h-0 {
    min-height: 0;
}

.min-w-24 {
    min-width: 6rem;
}

.min-h-24 {
    min-height: 6rem;
}

.min-w-32 {
    min-width: 8rem;
}

.min-h-32 {
    min-height: 8rem;
}

.min-w-40 {
    min-width: 10rem;
}

.min-h-40 {
    min-height: 10rem;
}

.min-w-48 {
    min-width: 12rem;
}

.min-h-48 {
    min-height: 12rem;
}

.min-w-140-px {
    min-width: 140px;
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-75 {
    opacity: 0.75;
}

.opacity-80 {
    opacity: 0.8;
}

.outline-none {
    outline: 0;
}

.focus\:outline-none:focus {
    outline: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.p-0 {
    padding: 0 !important;
}

.p-2 {
    padding: 0.5rem;
}

.p-3 {
    padding: 0.75rem;
}

.p-4 {
    padding: 1rem;
}

.p-5 {
    padding: 1.25rem;
}

.p-8 {
    padding: 2rem;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.pt-1 {
    padding-top: 0.25rem;
}

.pt-2 {
    padding-top: 0.5rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pt-3 {
    padding-top: 0.75rem;
}

.pr-3 {
    padding-right: 0.75rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pt-4 {
    padding-top: 1rem;
}

.pr-4 {
    padding-right: 1rem;
}

.pb-4 {
    padding-bottom: 1rem;
}

.pl-4 {
    padding-left: 1rem;
}

.pt-6 {
    padding-top: 1.5rem;
}

.pb-6 {
    padding-bottom: 1.5rem;
}

.pt-8 {
    padding-top: 2rem;
}

.pt-10 {
    padding-top: 2.5rem;
}

.pl-10 {
    padding-left: 2.5rem;
}

.pt-12 {
    padding-top: 3rem;
}

.pr-12 {
    padding-right: 3rem;
}

.pt-16 {
    padding-top: 4rem;
}

.pb-16 {
    padding-bottom: 4rem;
}

.pt-20 {
    padding-top: 5rem;
}

.pb-20 {
    padding-bottom: 5rem;
}

.pt-24 {
    padding-top: 6rem;
}

.pt-32 {
    padding-top: 8rem;
}

.pb-32 {
    padding-bottom: 8rem;
}

.pb-40 {
    padding-bottom: 10rem;
}

.pt-48 {
    padding-top: 12rem;
}

.pb-48 {
    padding-bottom: 12rem;
}

.pb-64 {
    padding-bottom: 16rem;
}

.placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.placeholder-gray-400:-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.pointer-events-none {
    pointer-events: none;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.top-0 {
    top: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.top-auto {
    top: auto;
}

.bottom-auto {
    bottom: auto;
}

.left-auto {
    left: auto;
}

.-right-100 {
    right: -100%;
}

.-top-225-px {
    top: -225px;
}

.-top-160-px {
    top: -160px;
}

.-top-150-px {
    top: -150px;
}

.-top-94-px {
    top: -94px;
}

.-left-50-px {
    left: -50px;
}

.-top-50-px {
    top: -50px;
}

.-top-29-px {
    top: -29px;
}

.-left-20-px {
    left: -20px;
}

.top-25-px {
    top: 25px;
}

.left-40-px {
    left: 40px;
}

.top-95-px {
    top: 95px;
}

.left-145-px {
    left: 145px;
}

.left-195-px {
    left: 195px;
}

.top-210-px {
    top: 210px;
}

.left-260-px {
    left: 260px;
}

.shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-none {
    box-shadow: none;
}

.hover\:shadow-md:hover {
    box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.4), 0 2px 8px -1px rgba(0, 0, 0, 0.05);
}

.hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -2px rgba(0, 0, 0, 0.5), 0 5px 15px -2px rgba(0, 0, 0, 0.1);
}

.focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.fill-current {
    fill: currentColor;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
}

.text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
}

.text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
}

.text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
}

.text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
}

.text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
}

.text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
}

.text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
}

.text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
}

.text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
}

.text-red-600 {
    --text-opacity: 1;
    color: #e04332;
    color: rgba(224, 67, 50, var(--text-opacity));
}

.text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
}

.text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
}

.text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
}

.text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
}

.text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
}

.text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
}

.text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
}

.text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
}

.hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
}

.hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
}

.hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
}

.hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
}

.hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e04332;
    color: rgba(224, 67, 50, var(--text-opacity));
}

.hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
}

.uppercase {
    text-transform: uppercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.no-underline {
    text-decoration: none;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tracking-wide {
    letter-spacing: 0.025em;
}

.align-middle {
    vertical-align: middle;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.break-words {
    overflow-wrap: break-word;
}

.w-5 {
    width: 1.25rem;
}

.w-8 {
    width: 2rem;
}

.w-10 {
    width: 2.5rem;
}

.w-12 {
    width: 3rem;
}

.w-16 {
    width: 4rem;
}

.w-auto {
    width: auto;
}

.w-1\/2 {
    width: 50%;
}

.w-3\/12 {
    width: 25%;
}

.w-6\/12 {
    width: 50%;
}

.w-9\/12 {
    width: 75%;
}

.w-10\/12 {
    width: 83.333333%;
}

.w-full {
    width: 100%;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.z-10 {
    z-index: 10;
}

.z-40 {
    z-index: 40;
}

.z-50 {
    z-index: 50;
}

.transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
        skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transition-all {
    transition-property: all;
}

.ease-linear {
    transition-timing-function: linear;
}

.duration-150 {
    transition-duration: 150ms;
}

@-webkit-keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ping {
    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes ping {
    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@-webkit-keyframes pulse {
    50% {
        opacity: 0.5;
    }
}

@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}

@-webkit-keyframes bounce {
    0%,
    100% {
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: none;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: none;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@media (min-width: 640px) {
    .sm\:block {
        display: block;
    }

    .sm\:hidden {
        display: none;
    }

    .sm\:mt-0 {
        margin-top: 0;
    }

    .sm\:ml-1 {
        margin-left: 0.25rem;
    }

    .sm\:mr-2 {
        margin-right: 0.5rem;
    }

    .sm\:mb-4 {
        margin-bottom: 1rem;
    }

    .sm\:pt-0 {
        padding-top: 0;
    }

    .sm\:pt-4 {
        padding-top: 1rem;
    }

    .sm\:w-3\/12 {
        width: 25%;
    }

    .sm\:w-6\/12 {
        width: 50%;
    }

    .sm\:w-9\/12 {
        width: 75%;
    }
}

@media (min-width: 768px) {
    .md\:block {
        display: block;
    }

    .md\:flex {
        display: flex;
    }

    .md\:hidden {
        display: none;
    }

    .md\:flex-row {
        flex-direction: row;
    }

    .md\:flex-col {
        flex-direction: column;
    }

    .md\:flex-no-wrap {
        flex-wrap: nowrap;
    }

    .md\:items-stretch {
        align-items: stretch;
    }

    .md\:justify-start {
        justify-content: flex-start;
    }

    .md\:justify-end {
        justify-content: flex-end;
    }

    .md\:justify-between {
        justify-content: space-between;
    }

    .md\:mt-0 {
        margin-top: 0;
    }

    .md\:mt-4 {
        margin-top: 1rem;
    }

    .md\:mb-4 {
        margin-bottom: 1rem;
    }

    .md\:mt-40 {
        margin-top: 10rem;
    }

    .md\:mt-64 {
        margin-top: 16rem;
    }

    .md\:ml-64 {
        margin-left: 16rem;
    }

    .md\:min-h-full {
        min-height: 100%;
    }

    .md\:min-w-full {
        min-width: 100%;
    }

    .md\:opacity-100 {
        opacity: 1;
    }

    .md\:overflow-hidden {
        overflow: hidden;
    }

    .md\:overflow-y-auto {
        overflow-y: auto;
    }

    .md\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .md\:pt-0 {
        padding-top: 0;
    }

    .md\:pb-2 {
        padding-bottom: 0.5rem;
    }

    .md\:pr-12 {
        padding-right: 3rem;
    }

    .md\:pt-32 {
        padding-top: 8rem;
    }

    .md\:fixed {
        position: fixed;
    }

    .md\:relative {
        position: relative;
    }

    .md\:top-0 {
        top: 0;
    }

    .md\:bottom-0 {
        bottom: 0;
    }

    .md\:left-0 {
        left: 0;
    }

    .md\:shadow-none {
        box-shadow: none;
    }

    .md\:text-left {
        text-align: left;
    }

    .md\:w-64 {
        width: 16rem;
    }

    .md\:w-2\/12 {
        width: 16.666666667%;
    }

    .md\:w-3\/12 {
        width: 25%;
    }

    .md\:w-4\/12 {
        width: 33.333333%;
    }

    .md\:w-5\/12 {
        width: 41.666667%;
    }

    .md\:w-6\/12 {
        width: 50%;
    }

    .md\:w-7\/12 {
        width: 58.333333333%;
    }

    .md\:w-8\/12 {
        width: 66.666667%;
    }

    .md\:w-9\/12 {
        width: 75%;
    }
}

@media (min-width: 1024px) {
    .lg\:bg-transparent {
        background-color: transparent;
    }

    .lg\:block {
        display: block;
    }

    .lg\:inline-block {
        display: inline-block;
    }

    .lg\:flex {
        display: flex;
    }

    .lg\:hidden {
        display: none;
    }

    .lg\:flex-row {
        flex-direction: row;
    }

    .lg\:self-center {
        align-self: center;
    }

    .lg\:justify-start {
        justify-content: flex-start;
    }

    .lg\:order-1 {
        order: 1;
    }

    .lg\:order-2 {
        order: 2;
    }

    .lg\:order-3 {
        order: 3;
    }

    .lg\:mb-0 {
        margin-bottom: 0;
    }

    .lg\:mb-12 {
        margin-bottom: 3rem;
    }

    .lg\:mr-1 {
        margin-right: 0.25rem;
    }

    .lg\:mr-4 {
        margin-right: 1rem;
    }

    .lg\:mt-8 {
        margin-top: 2rem;
    }

    .lg\:mt-16 {
        margin-top: 4rem;
    }

    .lg\:ml-auto {
        margin-left: auto;
    }

    .lg\:-ml-16 {
        margin-left: -4rem;
    }

    .lg\:-mt-64 {
        margin-top: -16rem;
    }

    .lg\:p-10 {
        padding: 2.5rem;
    }

    .lg\:py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .lg\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .lg\:pt-0 {
        padding-top: 0;
    }

    .lg\:pt-4 {
        padding-top: 1rem;
    }

    .lg\:pt-12 {
        padding-top: 3rem;
    }

    .lg\:pt-16 {
        padding-top: 4rem;
    }

    .lg\:pt-20 {
        padding-top: 5rem;
    }

    .lg\:pt-24 {
        padding-top: 6rem;
    }

    .lg\:pb-64 {
        padding-bottom: 16rem;
    }

    .lg\:static {
        position: static;
    }

    .lg\:shadow-none {
        box-shadow: none;
    }

    .lg\:text-left {
        text-align: left;
    }

    .lg\:text-right {
        text-align: right;
    }

    .lg\:text-white {
        --text-opacity: 1;
        color: #fff;
        color: rgba(255, 255, 255, var(--text-opacity));
    }

    .lg\:text-gray-300 {
        --text-opacity: 1;
        color: #e2e8f0;
        color: rgba(226, 232, 240, var(--text-opacity));
    }

    .lg\:hover\:text-gray-300:hover {
        --text-opacity: 1;
        color: #e2e8f0;
        color: rgba(226, 232, 240, var(--text-opacity));
    }

    .lg\:w-auto {
        width: auto;
    }

    .lg\:w-2\/12 {
        width: 16.666666667%;
    }

    .lg\:w-3\/12 {
        width: 25%;
    }

    .lg\:w-4\/12 {
        width: 33.333333%;
    }

    .lg\:w-5\/12 {
        width: 41.666667%;
    }

    .lg\:w-6\/12 {
        width: 50%;
    }

    .lg\:w-7\/12 {
        width: 58.333333333%;
    }

    .lg\:w-8\/12 {
        width: 66.666667%;
    }

    .lg\:w-9\/12 {
        width: 75%;
    }

    .hover\:-mt-4:hover {
        margin-top: -1rem;
    }
}

@media (min-width: 1280px) {
    .xl\:mb-0 {
        margin-bottom: 0;
    }

    .xl\:w-2\/12 {
        width: 16.666666667%;
    }

    .xl\:w-3\/12 {
        width: 25%;
    }

    .xl\:w-4\/12 {
        width: 33.333333%;
    }

    .xl\:w-5\/12 {
        width: 41.666667%;
    }

    .xl\:w-6\/12 {
        width: 50%;
    }

    .xl\:w-7\/12 {
        width: 58.333333333%;
    }

    .xl\:w-8\/12 {
        width: 66.666667%;
    }

    .xl\:w-9\/12 {
        width: 75%;
    }
}
