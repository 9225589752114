.lazy-load,
.offline-box {
    position: fixed;
    color: #4a5568;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    z-index: 99999;
    .linear-color {
        height: 0.25rem;
        background-color: #e1f5fe !important;
        .bar-color {
            background-color: #f04332 !important;
        }
    }
    .MuiBox-root {
        height: calc(100vh - 0.25rem);
        .MuiGrid-container {
            margin: 0;
        }
    }
}

nav {
    .social {
        margin-top: -0.25rem;
        @media (max-width: 1024px) {
            margin-bottom: 0.5rem;
            &.social-lg {
                display: none;
            }
            &.social-sm {
                margin: 1rem 0;
                .text-xs {
                    font-size: unset;
                }
            }
        }
        @media (min-width: 1024px) {
            &.social-lg {
                margin-left: -1.75rem;
            }
            &.social-sm {
                display: none;
            }
        }
    }
    .item-select-language {
        margin-top: -0.25rem;
        .MuiMenuItem-root {
            min-height: auto;
        }
        .MuiInput-underline {
            &:before,
            &:after {
                border: 0 !important;
            }
        }
        .MuiSelect-select {
            &:focus {
                background: none !important;
            }
        }
        img {
            width: 24px;
            height: 24px;
        }
        @media (max-width: 1024px) {
            .lg\:visible {
                display: none;
            }
            .lg\:hidden {
                margin: 1rem 0;
            }
            .select-language {
                display: inline-block;
            }
        }
    }
}

.select-language {
    img {
        width: 24px;
        height: 24px;
    }
}

.carousel-slider {
    @media (min-width: 640px) {
        .control-dots {
            bottom: 6rem;
        }
    }
    .control-arrow {
        opacity: 0.95 !important;
        filter: alpha(opacity=95) !important;
        top: calc(50% - 3rem) !important;
        width: 3em;
        height: 3em;
        border-radius: 3rem;
    }
    @media (max-width: 768px) {
        .control-arrow {
            top: calc(50% - 2rem) !important;
            width: 2em;
            height: 2em;
            border-radius: 2rem;
        }
    }
    .control-arrow-left {
        left: 1rem;
    }
    .control-arrow-right {
        right: 1rem;
    }
    .slide {
        background: #e2e8f0;
        .item {
            cursor: pointer;
            a {
                height: 100%;
            }
            .overall,
            iframe {
                top: 0;
                left: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                position: absolute;
            }
            img {
                max-height: 775px;
            }
            .overall {
                z-index: 1;
            }
        }
    }
}

.carousel {
    .thumbs-wrapper {
        .thumb.selected,
        .thumb:hover {
            border: 1px solid #e04332;
        }
        .control-arrow {
            opacity: 0.95 !important;
            filter: alpha(opacity=95) !important;
        }
    }
}

.inner-html {
    ul {
        list-style: circle;
    }
    ol {
        list-style: decimal;
    }
    ul li, ol li{
        margin-left: 1.25rem;
    }
    p {
        margin-block-start: 1rem;
        margin-block-end: 1rem;
    }
    p:last-child {
        margin-block-end: 0;
    }
    hr {
        margin: 1rem 0;
    }
    a {
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
    img {
        display: inline-block;
    }
}

.tours-filters {
    .MuiFormControl-root {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        margin-bottom: 1rem !important;
    }
}

.tour-info-block {
    padding: 2rem 1rem !important;
    margin: -0.5rem 0.5rem 0 !important;
    .MuiGrid-item {
        margin: 0 0 1rem !important;
    }
    .MuiGrid-item:last-child {
        margin: 0 !important;
    }
}

.tour-program-block {
    .day-name {
        margin: -1rem -1rem 1rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.tour-preview-block {
    .type-2 {
        a,
        span {
            color: #fff !important;
        }
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
        &.lg\:w-4\/12 {
            width: 50%;
        }
    }
    @media (max-width: 640px) {
        blockquote {
            min-height: 300px;
        }
    }
    @media (min-width: 640px) {
        blockquote {
            min-height: 300px;
        }
    }
    @media (min-width: 1024px) {
        img {
            height: 180px;
        }
        blockquote {
            min-height: 320px;
        }
    }
    @media (min-width: 1280px) {
        img {
            height: 240px;
        }
        blockquote {
            min-height: 340px;
        }
    }
}

.tour-contact-manager {
    color: #fff !important;
    margin-right: 1rem !important;
    display: inline-block;
    background-color: #7360f2;
    @media (max-width: 400px) {
        &:last-child {
            margin-top: 0.5rem;
        }
    }
}
.tour-contact-manager:hover {
    background-color: #766df2;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.color-calendar {
    .calendar__header {
        .calendar__monthyear {
            color: #222 !important;
        }
        .calendar__arrow-inner::after {
            opacity: 1 !important;
            background-color: #9e9e9e !important;
        }
        .calendar__arrow-inner:hover::after {
            background-color: #e04332 !important;
        }
    }
    .calendar__body {
        .calendar__day-active {
            .calendar__day-text {
                color: #222 !important;
            }
            .calendar__day-box {
                background: none !important;
                box-shadow: none !important;
            }
        }
        .calendar__day.calendar__day-event {
            .calendar__day-text {
                color: #fff !important;
            }
            .calendar__day-bullet {
                display: none !important;
            }
            .calendar__day-box,
            .calendar__day-box:hover {
                opacity: 1 !important;
                border-radius: 0.5rem !important;
                background-color: #e04332 !important;
            }
        }
        .calendar__picker-month-today::after,
        .calendar__picker-month-selected::after,
        .calendar__picker-year-today::after,
        .calendar__picker-year-selected::after {
            opacity: 0 !important;
        }
        .calendar__picker-month-option,
        .calendar__picker-year-option {
            color: #222 !important;
            background-color: #e2e8f0 !important;
            box-shadow: none !important;
        }
        .calendar__picker-month-selected,
        .calendar__picker-year-selected {
            color: #fff !important;
            background-color: #e04332 !important;
        }
        .chevron-thin {
            border-color: #e04332 !important;
        }
    }
}

.news-preview-block {
    @media (max-width: 640px) {
        blockquote {
            min-height: 160px;
        }
    }
    @media (min-width: 640px) {
        blockquote {
            min-height: 140px;
        }
    }
    @media (min-width: 1024px) {
        img {
            height: 180px;
        }
        blockquote {
            min-height: 220px;
            @media (max-width: 1280px) {
                .float-right {
                    float: left;
                    margin-left: 0.5rem;
                }
            }
        }
    }
    @media (min-width: 1280px) {
        img {
            height: 240px;
        }
        blockquote {
            min-height: 190px;
        }
    }
}

.partner-block {
    img {
        margin: 0 auto;
    }
}

.service-button {
    .cursor-pointer {
        min-height: 120px;
    }
}

.subscription {
    button:hover {
        background-color: #d04332;
    }
}

.subscription_popup_block {
    a.MuiButton-contained:hover {
        background-color: #d04332;
    }

    .MuiButton-root {
        line-height: inherit;
        border-radius: 0.25rem;
        @media (max-width: 1024px) {
            border-radius: 0;
            display: block;
            text-align: center;
        }
    }

    a.MuiButton-contained.px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    a.MuiButton-contained.py-3 {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .subscription.popup {
        @media (max-width: 1024px) {
            button {
                margin-top: 0.5rem;
            }
        }
        @media (min-width: 1024px) {
            input[type='email'], input[type='tel'] {
                border-radius: 0.25rem 0 0 0.25rem;
            }
            button {
                border-radius: 0 0.25rem 0.25rem 0;
            }
        }
    }
}

.subscription.footer {
    input[type='tel'] {
        border-radius: 0.25rem 0 0 0.25rem;
    }
    button {
        border-radius: 0 0.25rem 0.25rem 0;
    }
}

footer {
    a {
        color: #fff;
    }
    a:hover {
        text-decoration: underline;
    }
    a.ml-4 {
        margin-left: 1rem !important;
    }
    .whrs {
        .icon-block {
            width: 14px;
            display: inline-block;
        }
    }
    .MuiTypography-root.mb-5 {
        margin-bottom: 1.25rem;
    }
}

.alert-popup {
    padding: 2rem;
}

.action-block-callback,
.block-callback {
    .MuiButton-outlined {
        color: #4caf50;
        border-color: #4caf50;
        &:hover {
            color: #388e3c;
            border-color: #388e3c;
            background-color: #daf7db;
        }
    }
    .MuiButton-contained {
        background-color: #4caf50;
        &:hover {
            background-color: #388e3c;
        }
    }
}

.country-block {
    display: inline-block;
    margin-right: 0.5rem;
    .flag {
        cursor: pointer;
    }
}

.place-photos-block {
    .carousel {
        .thumbs-wrapper {
            margin: 0;
            .thumb {
                width: 80px !important;
                height: 50px;
                margin: 2px 0 0;
                border: 0;
            }
        }
    }
}

.photos-fullscreen-block {
    .MuiDialogTitle-root {
        color: #fff;
        width: 100%;
        z-index: 1;
        position: absolute;
        button {
            background: rgba(255, 255, 255, 0.25);
        }
    }
    .MuiDialogContent-root {
        .carousel-root {
            height: 100%;
            .carousel-slider {
                height: calc(100% - 78px);
                .slider-wrapper {
                    height: 100%;
                    .slider {
                        height: 100%;
                        align-items: center;
                        .slide {
                            background: none;
                            img {
                                width: auto !important;
                            }
                        }
                    }
                }
                .control-arrow {
                    background: rgba(0, 0, 0, 0.4);
                }
            }
            .carousel {
                .thumbs-wrapper {
                    text-align: center;
                    height: 78px;
                    margin: 0;
                    .thumb {
                        width: 120px !important;
                        height: 78px;
                        img {
                            height: 72px;
                        }
                    }
                }
            }
        }
        @media (max-width: 1024px) {
            padding: 0;
            .carousel-root {
                .carousel-slider {
                    height: 100%;
                    .slider-wrapper {
                        .slider {
                            .slide {
                                img {
                                    width: 100% !important;
                                }
                            }
                        }
                    }
                    .control-dots {
                        bottom: 1rem;
                    }
                    .control-arrow {
                        background: none;
                    }
                }
            }
        }
    }
}

.hotel-vid {
    color: #fff !important;
    background-color: #8bc34a !important;
}

.text-description-font {
    font-family: 'Arial', serif;
    font-size: 1.04rem;
    letter-spacing: .04rem;
}

.cookie_info {
    a {
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
    button {
        margin: 1.5rem -1rem 0;
        float: right;
    }
}

.tour-preview-block .tour-period,
.tour-preview-block .tour-date
{
    margin-left: 6px;
}

.tour-preview-block .tour-period #tour-period-field,
.tour-preview-block .tour-date #tour-date-field,
#menu-tour-period .MuiMenuItem-root,
#menu-tour-date .MuiMenuItem-root
{
    font-size: 0.875rem;
}

.tour-preview-block #tour-period-field,
.tour-preview-block #tour-date-field
{
    padding: 2px 24px 2px 8px;
}

.place-country-block img {
    @media (min-width: 768px) {
        max-height: 120px;
    }
    @media (min-width: 1280px) {
        max-height: 160px;
    }
}

/* Widgets */

.search-tour-container {
    .iframe-search-tour-preview {
        position: absolute;
        z-index: 1;
        left: 0;
        @media (max-width: 640px) {
            height: 480px;
        }
        @media (min-width: 640px) {
            margin-top: -5rem;
            min-height: 280px;
            height: 640px;
        }
        @media (min-width: 1280px) {
            min-height: 156px;
            height: auto;
            &:hover {
                height: 860px;
            }
        }
    }
}

.hot-tour-container {
    .iframe-hot-tour-preview {
        margin: 0 auto;
        @media (max-width: 768px) {
            height: 1910px;
            max-width: 316px;
        }
        @media (min-width: 768px) {
            height: 970px;
            max-width: 642px;
        }
        @media (min-width: 1024px) {
            height: 735px;
            max-width: none;
        }
        @media (min-width: 1280px) {
            height: 500px;
        }
    }
}

.search-tour-container .iframe-search-tour-overview,
.hot-tour-container .iframe-hot-tour-overview {
    height: calc(100vh - 98px);
    position: fixed;
    z-index: 1;
    left: 0;
    background-color: #fff;
    @media (max-width: 1024px) {
        height: calc(100vh - 92px);
    }
}

.hot-tour-container {
    .iframe-hot-tour-overview {
        padding-left: 1rem;
    }
}

.homepage-search-widget .search-tour-container {
    @media (max-width: 480px) {
        margin-bottom: 24rem;
    }
    @media (min-width: 480px) {
        margin-bottom: 14rem;
    }
    @media (min-width: 640px) {
        margin-bottom: 9rem;
    }
    @media (min-width: 1024px) {
        margin-bottom: 0;
    }
}

#itTourWidgetWrapper {
    @media (min-width: 640px) {
        #itTourWidget {
            margin-top: -5rem;
        }
    }
    .fd-nav_item {
        background-color: rgba(255, 255, 255, 0.75);
    }
}

#ittHS {
    .itt_hs_main_wrap.itt_border_color {
        height: 1600px !important;
    }
}
